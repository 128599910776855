<template>
  <div class="">
    <el-form :model="delivery" label-position="left" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="操作类型" prop="status">
        <el-radio-group v-model="delivery.status">
          <el-radio :label="1">通过</el-radio>
          <el-radio :label="2">拒绝</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="估值积分" prop="integral" v-if="delivery.status==1">
        <el-input v-model="delivery.integral" placeholder="请输入积分数"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark" v-if="delivery.status==2">
        <el-input type="textarea" rows="3" v-model="delivery.remark" placeholder="请输入备注"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">确认操作</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      delivery: {
        status: '',
        integral: '',
        remark: ''
      },
      rules: {
        status: [
          { required: true, message: '请选择操作类型', trigger: 'blur' }
        ],
        integral: [
          { required: true, message: '请填写积分', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '请填写备注', trigger: 'blur' }
        ]
      },
    }
  },
  components: {
  },
  props: {
    id: Number,
    callback: Function
  },
  created() {
  },
  mounted() {
  },
  methods: {
    submitForm() {
      var _this = this
      var params = this.delivery
      params.id = this.id
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          _this.$api.activity.exchangeShenhe(params, res => {
            if (res.errcode == 0) {
              _this.success(res.errmsg)
              _this.callback()
            } else {
              _this.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
</style>
