<template>
  <div class="main-box">
    <span class="mg-rt-20">订单状态</span>
    <el-radio-group v-model="search.status" border="true" size="small" @input="handleSearch">
      <el-radio-button label="0">全部</el-radio-button>
      <el-radio-button label="1">审核中</el-radio-button>
      <el-radio-button label="2">审核通过</el-radio-button>
      <el-radio-button label="3">审核失败</el-radio-button>
      <el-radio-button label="4">已发货</el-radio-button>
      <el-radio-button label="5">已完成</el-radio-button>
    </el-radio-group>
    <el-form class="form-inline mg-tp-10" :inline="true" size="small">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入关键词" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="下单时间">
        <el-date-picker
            size="small"
            v-model="dateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          prop="title"
          label="绘本名称"
          fixed="left"
          width="150">
      </el-table-column>
      <el-table-column
          prop="member_id"
          label="下单用户"
          align="center"
          width="240">
        <template slot-scope="scope">
          <div v-if="scope.row.member" class="member-box">
            <img :src="scope.row.member.avatar" />
            <div>
              <div>{{scope.row.member.name}}（{{scope.row.member.user_id}}）</div>
              <div>{{scope.row.member.mobile}}</div>
            </div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="mobile"
          label="联系方式"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="desc"
          label="绘本描述"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="imgs"
          label="绘本图片"
          width="450">
        <template slot-scope="scope">
          <img :src="item" v-for="(item,index) in scope.row.imgs" :key="index" style="width: 80px; height: 80px; margin-right: 10px">
        </template>
      </el-table-column>
      <el-table-column
          prop="grade"
          label="磨损程度"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="delivery_name"
          label="物流信息"
          align="center"
          width="200">
        <template slot-scope="scope">
          {{scope.row.delivery_name}} - {{scope.row.delivery_no}} <br>
          {{scope.row.delivery_time}}
        </template>
      </el-table-column>
      <el-table-column
          prop="integral"
          label="估值积分"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="status_str"
          label="订单状态"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="下单时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          prop="shenhe_time"
          label="审核"
          align="center"
          width="140">
        <template slot-scope="scope">
          {{scope.row.remark}} <br>
          {{scope.row.shenhe_time}}
        </template>
      </el-table-column>
      <el-table-column
          prop="finish_time"
          label="完成时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.status==1">
            <el-button type="text" size="small" @click="shenheOrder(scope.row)">审核</el-button>
          </span>
          <span v-if="scope.row.status==4">
            <el-popconfirm
                title="确认已收货并送积分给用户吗？"
                @confirm="sureOrder(scope.row)">
              <el-button type="text" size="small" slot="reference">确认</el-button>
            </el-popconfirm>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="审核"
        :visible.sync="dialogVisible"
        :destroy-on-close="true"
        width="600px">
      <exchange-shenhe :id="shenhe.id" :callback="shenheOk"></exchange-shenhe>
    </el-dialog>
  </div>
</template>
<script>

import ExchangeShenhe from "@/components/ExchangeShenhe";
export default {
  components: {
    ExchangeShenhe
  },
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      dialogVisible: false,
      search: {
        status: 0,
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      tableData: [],
      dateRange: ['',''],
      shenhe: {
        id: ''
      }
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        status: that.search.status,
      }
      if(this.dateRange) {
        param.start_time = this.dateRange[0]
        param.end_time = this.dateRange[1]
      }
      this.$api.activity.exchangeIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    shenheOrder(item) {
      this.shenhe.id = item.id
      this.dialogVisible = true
    },
    shenheOk() {
      this.shenhe.id = 0
      this.dialogVisible = false
      this.getList()
    },
    sureOrder(item) {
      var _this = this
      this.$api.activity.exchangeDelivery({id: item.id}, res => {
        if (res.errcode == 0) {
          _this.success(res.errmsg)
          _this.getList()
        } else {
          _this.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
.gdtable tr {
  background-color: unset;
  border-bottom: 1px solid #f5f5f5
}
.gdtable tr:last-child {
  border-bottom: none !important;
}
.gdtable td {
  padding: 5px 0;
}
.member-box {
  display: flex;
  align-items: center;
  text-align: left;
}
.member-box img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}
</style>
